import * as React from "react"
import Layout from "../components/layout"
import '../components/index.css'
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const pageStyles = {
  color: "#232129",
  padding: "96px 12px",
  fontFamily: "Roboto, sans-serif, serif",
  maxWidth: "95%",
  margin: "auto"
}

const PricingCard = ({ info }) => {
  return <div className="pricing-card"
    style={{
      display: "inline-flex",
      width: "24rem",
      maxWidth: "100%",
    }}>
    <div style={{
      boxShadow: "0px 0px 0px 2px #007788",
      width: "20rem",
      maxWidth: "100%",
      margin: "auto",
      borderRadius: "4px",
      textAlign: "left",
      padding: "1rem",
      marginBottom: "1.5rem",
      position: "relative"
    }}>
      <div style={{ fontSize: "150%", fontWeight: "600", fontFamily: "Roboto Serif" }}>{info.title}</div>
      <p>{info.description}</p>
      <p style={{
        minHeight: "13rem"
      }}>{[0, 1, 2, 3, 4].map(i => <p style={{ display: "flex" }}>
        {info.features.length > i ? <>
          <div style={{ marginRight: "0.5rem", color: "#007788" }}><TaskAltIcon /></div>
          <div>{info.features[i]}</div>
        </> : <></>}
      </p>)}</p>
      <p>Price: <b>{info.price}</b></p>
      <br />
      <div style={{
        background: "#007788",
        color: "white",
        textAlign: "center",
        padding: "0.5rem",
        borderRadius: "4px"
      }}><b>{info.cta}</b></div>
      {info.note && <div style={{ position: "absolute", bottom: "-0.5rem", transform: "translate(-0.5rem, 100%)", display: "flex", fontSize: "90%" }}>
        <div>*&#160;</div>
        <div>{info.note}</div>
        </div>}
    </div>
  </div>
}

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <div style={pageStyles}>
        <h1 style={{ textAlign: "center" }}>Pricing</h1>
        <div style={{ textAlign: "center" }}>
          <PricingCard info={{
            title: "Developer",
            description: "Great for developers looking to test and integrate the API into an application.",
            features: [
              <div>Access to the Food Information API with <b>50 sample dishes</b></div>,
              <div>Access to the Autocomplete API with <b>all dishes</b></div>,
              <div>For <b>development</b> and <b>non-commercial</b> use only</div>
            ],
            price: "Free",
            cta: "Get started"
          }} />
          <PricingCard info={{
            title: "Production",
            description: "Unlock access to our entire collection of food and dishes.",
            features: [
              <div>Access to the Food Information API with <b>all dishes</b></div>,
              <div>Access to the Autocomplete API with <b>all dishes</b></div>,
              <div>For <b>production</b> and <b>commercial</b> use</div>
            ],
            price: "$99 / year",
            cta: "Get started"
          }} />
          <PricingCard info={{
            title: "Snapshot",
            description: "Download a snapshot of our database and code to use in your own product.",
            features: [
              <div>The Food Information database with <b>all dishes</b></div>,
              <div>The <b>source code</b> of the Autocomplete API</div>,
              <div>For <b>production</b> and <b>commercial</b> use. Resell after modification only*</div>
            ],
            note: "To be allowed to resell, you have to make significant changes to the database and/or source code. You are not allowed to resell the data as received.",
            price: "$299 one-time fee",
            cta: "Download snapshot right now"
          }} />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Pricing Page</title>
